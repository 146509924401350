import React from "react"
import Layout from "../components/layout"
import "../style.scss"

const NotFoundPage = () => (
  <Layout>
    <div className="section hero is-fullheight-with-navbar">
      <div className="hero-body">
        <div className="container has-text-centered">
          404: ページがみつかりません。
        </div>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
