import React from "react"
import logo from "../images/logo_rgb_yoko_outline.png"

const Header = () => (
  <header>
    <nav className="navbar" role="navigation" aria-label="main navigation">
      <div className="navbar-brand">
        <a className="navbar-item" href="https://natade-coco.com">
          <img src={logo} alt="natadeCOCO" />
        </a>
      </div>
    </nav>
  </header>
)

export default Header
